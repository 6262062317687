import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CloudProcess from '../component/process/CloudProcess';
import AboutThree from '../component/about/AboutThree';

const Cloud = () => {

    return (
        <>
            <SEO title="Cloud" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne
                    title="Serviços de Cloud"
                    paragraph="Conheça mais sobre nosso processo de migração cloud."
                    styleClass="thumbnail-4"
                    mainThumb="/images/banner/banner-thumb-1.png"
                />
                <AboutThree />
                <CloudProcess />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Cloud;