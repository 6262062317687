import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';

import Index from './pages/Index';
import Development from './pages/Development';
import Cloud from './pages/Cloud';
import Contact from './pages/Contact';

import './assets/scss/app.scss';
import WhatsAppButton from './component/whatsapp/WhatsApp';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
	return (
		<HelmetProvider>
			<Router>
				<WhatsAppButton></WhatsAppButton>
				<ScrollToTop>
					<Routes>
						<Route path={process.env.PUBLIC_URL + "/"} element={<Index />} />
						<Route path={process.env.PUBLIC_URL + "/index"} element={<Index />} />
						<Route path={process.env.PUBLIC_URL + "/desenvolvimento"} element={<Development />} />
						<Route path={process.env.PUBLIC_URL + "/cloud"} element={<Cloud />} />
						<Route path={process.env.PUBLIC_URL + "/contato"} element={<Contact />} />
					</Routes>
				</ScrollToTop>
			</Router>
		</HelmetProvider>
	)
}

export default App;
