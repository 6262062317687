import React from 'react'
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet-async';


const SEO = ({ title }) => {
    let pageTitle = "Intrabit | " + title;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Intrabit | {title}</title>
                <meta name="title" content={pageTitle} data-react-helmet="true" />
                <meta name="robots" content="follow" />
                <meta name="description" content="Desenvolvimento de software personalizados, modernização de sistemas legados, soluções e migrações em nuvem (AWS, GCP, Azure)." />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            </Helmet>
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;