import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FaFacebookF, FaLinkedinIn, FaPhone, FaEnvelope } from "react-icons/fa";


const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Buscar..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form>
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/desenvolvimento"}>Desenvolvimento</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/cloud"}>Soluções Cloud</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Fale conosco</span>
                                    <a target="_blank" rel="noopener noreferrer" href="https://wa.me/5543996184743" className="tel"><FaPhone /> (43) 99618-4743</a>
                                    <a href="mailto:contato@intrabit.com.br" className="email"><FaEnvelope /> contato@intrabit.com.br</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Redes sociais</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li>
                                            <a href="https://facebook.com/intrabit"><FaFacebookF /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/intrabit-solucoes/"><FaLinkedinIn /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
