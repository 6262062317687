import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import DevelopmentProcess from '../component/process/DevelopmentProcess';
import AboutThree from '../component/about/AboutThree';

const Development = () => {

    return (
        <>
        <SEO title="Desenvolvimento" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Serviços de Desenvolvimento"
                paragraph ="Conheça mais sobre nosso processo de desenvolvimento."
                styleClass="thumbnail-4"
                mainThumb="/images/banner/banner-thumb-1.png"
                />
                <AboutThree />
                <DevelopmentProcess />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Development;