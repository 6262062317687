import React from 'react';
import { FaWhatsapp } from "react-icons/fa";
import '../whatsapp/WhatsApp.css'


function WhatsAppButton() {
    return (
        <a target="_blank" rel="noopener noreferrer" href="https://wa.me/5543996184743" className="btn-whatsapp-pulse btn-whatsapp-pulse-border">
            <FaWhatsapp />
        </a>
    );
}

export default WhatsAppButton;