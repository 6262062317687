import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import FormTwo from '../component/contact/FormTwo';

const Contact = () => {
    return (
        <>
            <SEO title="Contato" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne
                    title="Contato"
                    page="Contato"
                />

                <div className="section section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6">
                                <div className="contact-form-box shadow-box mb--30">
                                    <h3 className="title">Receba um orçamento grátis</h3>
                                    <FormTwo />
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 offset-xl-1">
                                <div className="contact-info mb--100 mb_md--30 mt_md--0">
                                    <h4 className="title">Telefone</h4>
                                    <p>Horários de atendimento: </p>
                                    <p>08:00AM - 18:00PM (Segunda a Sexta)</p>
                                    <h4 className="phone-number"><a target="_blank" rel="noopener noreferrer" href="https://wa.me/5543996184743">(43) 99618-4743</a></h4>
                                </div>
                                <div className="contact-info mb--30">
                                    <h4 className="title">E-mail</h4>
                                    <p>Nosso time irá retornar no máximo em 48h úteis.</p>
                                    <h4 className="phone-number"><a href="mailto:contato@intrabit.com.br">contato@intrabit.com.br</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-12">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Bubble" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" /></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle" /></li>
                    </ul>
                </div>

                <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default Contact;