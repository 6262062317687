import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Estrutura e processos",
        para: "Com uma estrutura solida e processos bem definidos garantimos o sucesso de sua solução."
    },
    {
        id: 2,
        title: "Transparência nos processos",
        para: "Trabalhamos junto ao cliente, oferecendo feedback constantes para mostrar como está a solução."
    },
    {
        id: 3,
        title: "Voltado ao cliente",
        para: "Oferecemos soluções personalizadas para cada cliente, garantindo a satisfação."
    },
    {
        id: 4,
        title: "Stacks Personalizadas",
        para: "Com base nas necessidades do cliente, escolhemos a stack que mais se adequa."
    },
    {
        id: 5,
        title: "Documentações",
        para: "Para facilitar a manutenção e entendimento contamos com documentações detalhadas da solução desenvolvida."
    },
    {
        id: 6,
        title: "Clean Code",
        para: "Contamos com padrões de código para garantir as boas práticas e o entendimento da solução."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Nossos valores"
                    title="Porque nos escolher?"
                    description="Conheça mais sobre nossa política e nossa ética de trabalho."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;